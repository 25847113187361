<template>
<div id="loading-screen" v-if="showDialog">
  <div id="loading-bar">
    <slot></slot>
  </div>
</div>
</template>
<script>

export default {
  props: {
    showDialog: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
#loading-screen {
position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
#loading-bar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 10px solid rgba(255, 255, 255, 0.3);
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}
</style>