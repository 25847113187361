import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import 'vue-universal-modal/dist/index.css'
import VueUniversalModal from 'vue-universal-modal'
import mitt from 'mitt'
import { createMetaManager } from 'vue-meta'
import { VueCookies } from "vue-cookies"
import api from './components/common/RestAPI'
import Notifications from '@kyvg/vue3-notification'
import Adsense from 'vue-google-adsense/dist/Adsense.min.js'
import InArticleAdsense from 'vue-google-adsense/dist/InArticleAdsense.min.js'
import InFeedAdsense from 'vue-google-adsense/dist/InFeedAdsense.min.js'
import ScriptX from 'vue-scriptx'
import vue3GoogleLogin from 'vue3-google-login'

import "primeicons/primeicons.css"
import "./style/style.css"
import "./style/flags.css"
import PrimeVue from 'primevue/config'
import Noir from './preset/Noir'
import Button from 'primevue/button'
import Accordion from 'primevue/accordion'
import AccordionPanel from 'primevue/accordionpanel'
import AccordionHeader from 'primevue/accordionheader'
import AccordionContent from 'primevue/accordioncontent'
import Stepper from 'primevue/stepper'
import StepList from 'primevue/steplist'
import StepPanels from 'primevue/steppanels'
import StepItem from 'primevue/stepitem'
import Step from 'primevue/step'
import StepPanel from 'primevue/steppanel'
import Fieldset from 'primevue/fieldset'
import Tabs from 'primevue/tabs'
import TabList from 'primevue/tablist'
import Tab from 'primevue/tab'
import TabPanels from 'primevue/tabpanels'
import TabPanel from 'primevue/tabpanel'
import Card from 'primevue/card'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import ConfirmDialog from 'primevue/confirmdialog'
import Toast from 'primevue/toast';
import ConfirmationService from 'primevue/confirmationservice'
import ToastService from 'primevue/toastservice'
import Breadcrumb from 'primevue/breadcrumb'
import Menubar from 'primevue/menubar'
import FloatLabel from 'primevue/floatlabel'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import Select from 'primevue/select'
import Tooltip from 'primevue/tooltip'
import Tag from 'primevue/tag';
import Timeline from 'primevue/timeline'
import Divider from 'primevue/divider'
import Checkbox from 'primevue/checkbox'
import Galleria from 'primevue/galleria'
import Dialog from 'primevue/dialog'
import Chip from 'primevue/chip'
import InputGroup from 'primevue/inputgroup'
import InputGroupAddon from 'primevue/inputgroupaddon'
import SpeedDial from 'primevue/speeddial'
import ProgressSpinner from 'primevue/progressspinner'
import ProgressBar from 'primevue/progressbar'
import Textarea from 'primevue/textarea'
import Ripple from 'primevue/ripple'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import SplitButton from 'primevue/splitbutton'

// const AWS = require('aws-sdk')
// https://data-jj.tistory.com/53 - Redirect_URL
window.Kakao.init(process.env.VUE_APP_KAKAO_JAVA_SCRIPT_KEY)    // JAVA_SCRIPT_KEY

const emitter = mitt()
const app = createApp(App)

// AWS.config.region = 'ap-northeast-2'
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//   IdentityPoolId: 'ap-northeast-2:53a1ff0b-4f33-4181-b416-a62e5d1a507d',
// })
// const s3 = new AWS.S3({
//   accessKeyId: process.env.S3_ACCESS_KEY_ID,
//   secretAccessKey: process.env.S3_SECRET_ACCESS_KEY, 
//   region: process.env.S3_REGION
// })

// app.config.globalProperties.s3 = s3
app.config.globalProperties.emitter = emitter
app.config.globalProperties.api = api
// app.config.globalProperties.router = router

app.use(VueUniversalModal, {
  teleportTarget: '#modals'
})

app.use(PrimeVue, {
  ripple: true,
  theme: {
    preset: Noir,
    options: {
      prefix: 'p',
      darkModeSelector: '.p-dark',
      cssLayer: false,
    }
  }
})

app.directive('tooltip', Tooltip)
app.directive('ripple', Ripple)

app.component('InputIcon', InputIcon)
app.component("AccordionContent", AccordionContent)
app.component("AccordionHeader", AccordionHeader)
app.component("AccordionPanel", AccordionPanel)
app.component("Accordion", Accordion)
app.component("Stepper", Stepper)
app.component("StepList", StepList)
app.component("StepPanels", StepPanels)
app.component("StepItem", StepItem)
app.component("Step", Step)
app.component("StepPanel", StepPanel)
app.component("Fieldset", Fieldset)
app.component("Tabs", Tabs)
app.component("TabList", TabList)
app.component("Tab", Tab)
app.component("TabPanels", TabPanels)
app.component("TabPanel", TabPanel)
app.component("Card", Card)
app.component("DataTable", DataTable)
app.component("ColumnGroup", ColumnGroup)
app.component("Row", Row)
app.component("Column", Column)
app.component("Toast", Toast)
app.component("ConfirmDialog", ConfirmDialog)
app.component("Breadcrumb", Breadcrumb)
app.component("Menubar", Menubar)
app.component("FloatLabel", FloatLabel)
app.component("InputText", InputText)
app.component("InputNumber", InputNumber)
app.component("Splitter", Splitter)
app.component("SplitterPanel", SplitterPanel)
app.component("Select", Select)
app.component("Tag", Tag)
app.component("Timeline", Timeline)
app.component("Divider", Divider)
app.component("Checkbox", Checkbox)
app.component("Galleria", Galleria)
app.component("Dialog", Dialog)
app.component('Chip', Chip);
app.component('InputGroup', InputGroup)
app.component('InputGroupAddon', InputGroupAddon)
app.component('SpeedDial', SpeedDial)
app.component('ProgressSpinner', ProgressSpinner)
app.component('ProgressBar', ProgressBar)
app.component('Textarea', Textarea)
app.component('Button', Button)
app.component('IconField', IconField)
app.component('SplitButton', SplitButton)


app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID
})
app.use(ToastService)
app.use(ConfirmationService)
app.use(ScriptX)
app.use(Adsense)
app.use(InArticleAdsense) 
app.use(InFeedAdsense)
app.use(VueCookies)
app.use(router) 
app.use(Notifications)
app.use(createMetaManager())
app.mount('#app')


// JSON-LD : https://medium.com/@fmoessle/json-ld-with-vue-3-or-nuxt-3-a70a5d905b4a
// JSON-LD Format : https://developers.google.com/search/docs/appearance/structured-data/recipe?hl=ko#recipe_properties
// JSON-LD Test : https://search.google.com/test/rich-results?utm_campaign=devsite&utm_medium=jsonld&utm_source=recipe
// JSON-LD Test : https://validator.schema.org/
// 사이트 성능 측정 : https://pagespeed.web.dev/
// npm run build
// npx serve -s dist