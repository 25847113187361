import { createWebHistory, createRouter } from 'vue-router';

const routes = [
  
  {
    path: '/',
    name: 'home',
    component: () => import('@/Home.vue'),
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/Order.vue'),
  },
  {
    path: '/helper',
    name: 'helper',
    component: () => import('@/OrderHelper.vue'),
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('@/List.vue'),
  },
  {
    path: '/qna',
    name: 'qna',
    component: () => import('@/Qna.vue'),
  },
  {
    path: '/modify',
    name: 'modify',
    component: () => import('@/Modify.vue'),
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/Admin.vue'),
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/Faq.vue'),
  },
  {
    path: '/mypage',
    name: 'mypage',
    component: () => import('@/Mypage.vue'),
  },
  {
    path: '/contract',
    name: 'contract',
    component: () => import('@/Contract.vue'),
  },
  {
    path: '/biz',
    name: 'biz',
    component: () => import('@/Biz.vue'),
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('@/Shop.vue'),
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('@/Notice.vue'),
  },
  {
    path: '/market',
    name: 'market',
    component: () => import('@/Market.vue'),
  },
  {
    path: '/myinfo',
    name: 'myinfo',
    component: () => import('./components/mypage/Myinfo.vue'),
  },
  {
    path: '/sell',
    name: 'sell',
    component: () => import('./components/mypage/Sell.vue'),
  },
  {
    path: '/hot',
    name: 'hot',
    component: () => import('@/Hot.vue'),
  },
  {
    path: '/cat',
    name: 'cat',
    component: () => import('./components/game/Cat.vue'),
  },
  {
    path: '/tetris',
    name: 'tetris',
    component: () => import('./components/game/Tetris.vue'),
  },
  {
    path: '/excel',
    name: 'excel',
    component: () => import('./components/game/Excel.vue'),
  },  
  {
    path: '/stock',
    name: 'stock',
    component: () => import('./components/game/Stock.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('./components/game/News.vue'),
  },
  {
    path: '/trade',
    name: 'trade',
    component: () => import('@/Trade.vue'),
  },
  {
    path: '/write',
    name: 'write',
    component: () => import('@/Write.vue'),
  },
  {
    path: '/content',
    name: 'content',
    component: () => import('@/Content.vue'),
  },
  {
    path: '/policy/:index',
    name: 'policy',
    component: () => import('./components/policy/Policy.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
export default router;