<template>
  <div>
    <div class="container_info">
      <div class="info1">하나됨</div>
      <div class="info1">예금주 : 하나됨</div> 
      <div class="info2"><a href="https://sosoceo.tistory.com/category/GEEK9" target="_blank">BLOG</a></div> 
    </div>
    <div class="container_info">
      <div class="info1">배성호(010-8235-0642)</div>
      <div class="info1">카카오뱅크 3333-24-9473287</div>
      <div class="info2"><a href="https://www.instagram.com/geek9geek9/" target="_blank">INSTAGRAM</a></div>
    </div>
    <div class="container_info">
      <div class="info1">628-27-00300</div>
      <div class="info1">국민은행 420401-04-221057</div>
      <div class="info2"><a href='/notice'>공지</a></div>
    </div>
    <div class="container_info">
      <div class="info1">2018-인천연수구-0625</div>
      <div class="info1"><a href="https://qr.kakaopay.com/Ej79lhQuc" target="_blank">카카오페이 (모바일전용)</a></div>
      <div class="info2">
        <a href='http://cafe.naver.com/geek9'>카페</a>
      </div>
    </div>
    <div class="container_info">
      <div class="info1">인천 예술로 226번길 17, A-608</div>
      <div class="info1">Copyright © <a href="http://geek9.kr" target="_blank"><strong>geek9.kr</strong></a></div>
      <div class="info2"><a href="https://geek9.kr/biz" target="_blank">B2B</a></div>
    </div> 
    <div class="container_policy"> 
      GEEK9는 해외구매 및 배송을 중계·대행하며, 사이트에 등록된 상품의 이미지 및 내용에 대해서는 일체의 책임이 없습니다.
      각국의 관세법 및 관련법규를 준수하며 불법적인 행위에는 동의하지 않습니다.
      자가사용목적을 제외한 판매 및 사업용도 등으로 구입한 상품은 사업자명의로 배송신청을 하셔야하며,
      고객님의 허위신고로 인한 피해에 대해서는 GEEK9에 책임이 없음을 알려드립니다.
    </div>                 
  </div> 
</template>

<script>
export default {
}
</script>

<style scoped>
.main {
  font-size: 10px;
}
.container_info {
  display: flex;
}
.info1 {
  width: 40%;
  padding-left: 10px;
  padding-bottom: 10px;
  border-right: 1px dotted gray;
}
.info2 {
  width: 20%;
  padding-left: 10px;
  padding-bottom: 10px;
  /* border-right: 1px dotted gray; */
}
.container_policy {
  padding: 10px;
  font-weight: bold;
}
</style>
