<template>
  <slot></slot>
</template>

<script>
import { provide, reactive, watch } from 'vue'

export default {
  name: 'MetaManager',
  props: {
    initialMeta: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const meta = reactive(props.initialMeta)
    const setMeta = (newMeta) => {
      Object.assign(meta, newMeta)
    }

    provide('setMeta', setMeta)

    watch(meta, (newMeta) => {
      document.title = newMeta.title || ''
      
      // 기존 메타 태그 제거
      document.querySelectorAll('meta').forEach(el => {
        if (el.getAttribute('data-vue-meta') !== null) {
          el.remove()
        }
      })

      // ld+json 삭제
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          el.remove()
        }
      })

      // 새 메타 태그 추가
      Object.entries(newMeta).forEach(([key, value]) => {
        if (key !== 'title') {
          const meta = document.createElement('meta')
          if (key.startsWith('og:')) meta.setAttribute('property', key)
          else meta.setAttribute('name', key)

          meta.setAttribute('content', value)
          meta.setAttribute('data-vue-meta', '')
          document.head.appendChild(meta)
        }
      })
    }, { deep: true })
  }
}
</script>